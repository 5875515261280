import React, { memo, useCallback, useMemo, useState } from 'react'
import { ContractorDetails } from 'src/interfaces/contractor'
import { ContractorStepDocument } from './ContractorStepDocument'
import { ContractorStepActionsButton } from './ContractorStepActionsButton'
import tw from 'twin.macro'
import { Tooltip, Typography } from '@mui/material'
import { ContractorStepStatus } from './ContractorStepStatus'
import { CollectedData } from 'src/interfaces/collectedData'
import {
  collectedDataStepIconsMap,
  CollectedDataStatus,
  CollectedDataStepType,
} from 'src/constants/collectedData'
import { getCollectDataStepTitle } from 'src/utils/collectedData'
import { ChevronDownMinor, ChevronUpMinor, QuestionMarkMinor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'

interface Props {
  collectedData: CollectedData
  contractorDetails: ContractorDetails
  onRefreshContractors(): void
  collapsible?: boolean
  delayed?: boolean
}

const COLLECTED_DATA_STATUSES_WITHOUT_CONTENT = [
  CollectedDataStatus.Created,
  CollectedDataStatus.InProgress,
  CollectedDataStatus.WaitingForInternalApproval,
]

const COLLECTED_DATA_STEP_TYPES_WITHOUT_CONTENT = [
  CollectedDataStepType.StitchPayment,
  CollectedDataStepType.InPersonMeeting,
]

const ContractorStepItemComponent = ({
  collectedData,
  contractorDetails,
  onRefreshContractors,
  collapsible,
  delayed,
}: Props) => {
  const [collapsed, setCollapsed] = useState(collapsible ?? false)

  const stepCanBeRecollected = useMemo(
    () =>
      contractorDetails.collectDataTemplate?.collectDataTemplateSteps?.some(
        (template) =>
          template.label === collectedData.collectDataStep.label &&
          template.type === collectedData.collectDataStep.type,
      ),
    [collectedData],
  )

  const hasContent = useMemo(
    () =>
      !COLLECTED_DATA_STATUSES_WITHOUT_CONTENT.includes(collectedData.status) &&
      !COLLECTED_DATA_STEP_TYPES_WITHOUT_CONTENT.includes(collectedData.collectDataStep.type),
    [collectedData],
  )

  const toggleCollapse = useCallback(() => {
    if (!collapsible) {
      return
    }
    setCollapsed((prev) => !prev)
  }, [collapsible])

  const renderHeader = useCallback(
    (collapsible: boolean) => (
      <div>
        {delayed && (
          <div css={tw`px-4 py-2.5 bg-[#D8E5FF] flex flex-row items-center`}>
            <Typography css={tw`mr-1`} color={colors.COLORS_BLUE_500} variant="overline">
              DELAYED STEP
            </Typography>
            <Tooltip
              color="black"
              title={
                <>
                  <Typography css={tw`mb-2`} variant="h6" color={colors.SHADES_GREY_200}>
                    Delayed step
                  </Typography>
                  <Typography variant="body2" color={colors.SHADES_GREY_300}>
                    A step the contractor cannot complete without a prior step being approved first.
                    To initiate this step for a contractor, please approve all steps in 'Waiting for
                    Approval' tab. They will then be sent a notification with a link to continue
                    onboarding.
                  </Typography>
                </>
              }
              placement="right-end"
            >
              <span css={tw`h-5 cursor-pointer`}>
                <QuestionMarkMinor width={20} height={20} fill={colors.COLORS_BLUE_500} />
              </span>
            </Tooltip>
          </div>
        )}
        <div css={tw`px-4 py-3 flex items-center justify-between`}>
          <div css={tw`flex items-center`}>
            <div>{collectedDataStepIconsMap[collectedData.collectDataStep.type]}</div>
            <div css={tw`ml-3`}>
              <Typography variant="subtitle1">{getCollectDataStepTitle(collectedData)}</Typography>
              <ContractorStepStatus
                recollecting={
                  collectedData.isRecollecting ||
                  (collectedData.status === CollectedDataStatus.Created &&
                    !!collectedData.reviewMetadata?.declineReason)
                }
                status={collectedData.status}
              />
            </div>
          </div>
          <div css={tw`flex flex-row items-center`}>
            {collectedData.status === CollectedDataStatus.WaitingForApproval ||
            stepCanBeRecollected ? (
              <ContractorStepActionsButton
                contractorDetails={contractorDetails}
                collectedData={collectedData}
                recollecting={collectedData.isRecollecting}
                stepCanBeRecollected={stepCanBeRecollected}
                onAction={onRefreshContractors}
              />
            ) : null}
            {collapsible && hasContent && (
              <div
                css={tw`p-1 rounded bg-[#EDEDED] ml-2 h-[28px] cursor-pointer hover:bg-[#DAD9E0] active:bg-[#B4AFC0]`}
                onClick={toggleCollapse}
              >
                {collapsed ? <ChevronDownMinor width={20} /> : <ChevronUpMinor width={20} />}
              </div>
            )}
          </div>
        </div>
      </div>
    ),
    [
      collectedData,
      contractorDetails,
      collectedData,
      stepCanBeRecollected,
      collapsed,
      collapsible,
      delayed,
    ],
  )

  return (
    <div css={tw`bg-white rounded-lg mb-2 overflow-hidden`}>
      {renderHeader(collapsible ?? false)}
      <ContractorStepDocument
        header={renderHeader(false)}
        collectedData={collectedData}
        contractorDetails={contractorDetails}
        collapsed={collapsed}
      />
    </div>
  )
}

export const ContractorStepItem = memo(ContractorStepItemComponent)
