import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { gridFormatters, gridValueGetters } from 'src/utils/grid'
import { Payment } from 'src/interfaces/payment'
import { PaymentStatusChip } from 'src/components/payment/grid/PaymentStatusChip'
import { PaymentsItemActionButton } from 'src/components/payment/grid/action/PaymentsItemActionButton'
import { PaymentStatus } from './enums'

const statusesWithActions = [PaymentStatus.Paid, PaymentStatus.NotPaid]

export const paymentColumns: Array<GridColDef> = [
  {
    field: 'partnerDriverExternalId',
    headerName: 'Contractors ID',
    flex: 2,
  },
  {
    field: 'name',
    headerName: 'Contractors name',
    flex: 2,
    sortable: true,
    valueGetter: (params: GridValueGetterParams<Payment>) =>
      `${params.row.partnerDriver.firstName} ${params.row.partnerDriver.lastName}`,
  },
  {
    field: 'uploadedAt',
    headerName: 'Added on',
    flex: 2,
    sortable: true,
    type: 'dateTime',
    valueGetter: gridValueGetters.parseDate,
    valueFormatter: gridFormatters.formatShortDate,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams<Payment>) => (
      <PaymentStatusChip status={params.value} />
    ),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 2,
    sortable: true,
    valueFormatter: gridFormatters.formatCurrency,
  },
  {
    field: 'net_amount',
    headerName: 'Net amount',
    flex: 2,
    sortable: true,
    valueGetter: (params: GridValueGetterParams<Payment>) => {
      return params.row.partnerDriverEarningsPayouts.reduce(
        (acc, item) => acc + item.payout.netAmount,
        0,
      )
    },
    valueFormatter: gridFormatters.formatCurrency,
  },
  {
    field: 'paidAt',
    headerName: 'Paid on date',
    flex: 2,
    sortable: true,
    type: 'dateTime',
    valueGetter: gridValueGetters.parseDate,
    valueFormatter: gridFormatters.formatShortDate,
  },
  {
    field: 'payoutMethod',
    headerName: 'Payout Method',
    flex: 2,
    sortable: true,
  },
  {
    field: 'failureReason',
    headerName: 'Failure reason',
    flex: 2,
    sortable: true,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    sortable: true,
    disableExport: true,
    renderCell: (params: GridRenderCellParams<Payment>) => (
      <PaymentsItemActionButton
        disabled={!statusesWithActions.includes(params.row.status)}
        payment={params.row}
      />
    ),
  },
]

export const paymentPageSizeOptions = [5, 10, 20, 50, 100]

export const ONBOARDINGS_COLUMNS_KEY = 'paymentsColumns'
