import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { gridFormatters, gridValueGetters } from 'src/utils/grid'
import { Contractor } from 'src/interfaces/contractor'
import { ContractorStatusChip } from 'src/components/contractor/grid/ContractorStatusChip'
import { TableTags } from 'src/components/tags/TableTags'
import { ContractorState, ContractorStatus } from './enums'
import { Tag } from 'src/interfaces/tag'

const sharedContractorColumns: Array<GridColDef> = [
  {
    field: 'externalId',
    headerName: 'Contractors ID',
    flex: 2,
    sortable: true,
  },
  {
    field: 'name',
    headerName: 'Contractors name',
    flex: 2,
    sortable: true,
  },
]

const statusCol = {
  field: 'status',
  headerName: 'Status',
  flex: 2,
  sortable: true,
  valueGetter: (params: GridValueGetterParams<Contractor>) => {
    if (params.row?.expiringSoonDocuments) {
      return ContractorStatus.ExpiresSoon
    }

    return params.row?.isActive ? ContractorStatus.Active : ContractorStatus.Deactivated
  },
  renderCell: (params: GridRenderCellParams<Contractor>) => {
    return <ContractorStatusChip status={params.value} />
  },
}

const lastColumns = [
  {
    field: 'tags',
    headerName: 'Tag(s)',
    flex: 3,
    sortable: true,
    sortComparator: (v1: Tag[], v2: Tag[]) => {
      return v1.length - v2.length
    },
    valueGetter: (params: GridValueGetterParams<Contractor>) =>
      params.row.tags.filter((item) => !!item).map((item) => item.name),
    renderCell: (params: GridRenderCellParams<Contractor>) =>
      params.row?.tags.filter((item) => !!item).length ? (
        <TableTags tags={params.row?.tags.filter((item) => !!item) || []} />
      ) : (
        <span>-</span>
      ),
  },
  {
    field: 'referral',
    headerName: 'Referral',
    flex: 2,
    sortable: true,
    valueGetter: (params: GridValueGetterParams<Contractor>) => params.value || '-',
  },
]

export const contractorColumns = {
  [ContractorState.Active]: [
    ...sharedContractorColumns,
    statusCol,
    ...lastColumns,
    {
      field: 'onboardedAt',
      headerName: 'Date onboarded',
      flex: 2,
      sortable: true,
      valueGetter: gridValueGetters.parseDate,
      valueFormatter: gridFormatters.formatShortDate,
    },
  ],
  [ContractorState.Deactivated]: [
    ...sharedContractorColumns,
    ...lastColumns,
    {
      field: 'deactivatedAt',
      headerName: 'Date deactivated',
      flex: 2,
      sortable: true,
      valueGetter: gridValueGetters.parseDate,
      valueFormatter: gridFormatters.formatShortDate,
    },
  ],
  [ContractorState.Recollection]: [...sharedContractorColumns, statusCol, ...lastColumns],
}

export const contractorInitialSortFields = {
  [ContractorState.Active]: 'onboardedAt',
  [ContractorState.Deactivated]: 'deactivatedAt',
  [ContractorState.Recollection]: undefined,
}

export const contractorPageSizeOptions = [5, 10, 20, 50, 100]

export const ONBOARDINGS_COLUMNS_KEY = 'contractorsColumns'
